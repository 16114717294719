.table {
    padding: 0.5rem;
    width: 97%;
}

.table table {
    width: 100% !important;
}
.button {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
    align-items: flex-end;
    height: 100%;
}

.button button {
    background: rgb(46, 170, 196);
    color: white;
    font-size: 1rem;
    border: none;
    height: 2rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #ccc;
    cursor: pointer;
}

.title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-image: url('../../assets/icon_modal.png');
    background-size: cover;
    align-items: center;
    height: 3rem;
}

.title img{
    width: 2rem;
    margin-right: 1.5rem;
}
.title h1{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

.recommendations{
    border: 1px solid #a39c9c;
    border-radius: 5px;
    padding: 0.5rem;
}

.divRecommendations{
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.select{
    box-shadow: 0px 0px 7px 0px #00000029;
    border: none;
    height: 2.2rem;
    border-radius: 4px;
    width: 81%;
    margin-left: 0.5rem;
    font-weight: 400;
    color: #5A5A5A;
    font-size: 0.95rem;
    outline: none;
}