.container{
    display: flex;
    flex-direction: column;
    background: white;
    margin-top: 1rem;
    padding: 2rem 1rem 1rem 1rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px #00000029;
}

.title{
    color: #010066;
    height: 4rem;
    background-color: #DFDFDF;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.5rem 0rem 1.5rem;
}
.title h2{
    font-family: 'Poppins', sans-serif !important;
    font-size: 1.2rem;
}
.title button{
    background: #010066;
    border: unset;
    padding: 0.5rem;
    border-radius: 7px;
    height: 2rem;
    cursor: pointer !important;;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 6px #00000029;
}

.title button> img{
    width: 19%;
    margin-right: 0.3rem;
}



.title label{
    color: #ffff;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: start;
    margin-left: 0.3rem;
    font-family: 'Poppins', sans-serif !important;
    cursor: pointer;
}
.main{
    padding: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
}
.background{
    background-image: url('../../assets/capa_branca.png');
    background-repeat: no-repeat;
    background-size: cover;
    zoom: 0.8;
}
@media (min-height: 900px){
    .background{
        zoom: 1;
    }
}

.mainProduct{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top:1rem;
    gap: 1%;
    margin-left: 0.5rem;
}
.divProduct{
    display: flex;
    flex-direction: row;
    width: 49.2%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    height: 3.5rem;    
 }
 .inputProduct{
    box-shadow: 0px 0px 7px 0px #00000029;
    height: 2.5rem;
    border-radius: 4px;
    width: 100vh;
    font-weight: 400;
    outline: none;
    margin-right: 0.5rem;
    color: #5A5A5A;
    font-size: 0.95rem;
    border: 1px solid #00000033;
    padding-left: 0.5rem;
    max-width: 376px;
    max-height: 40px;
}
.selectProduct{
    box-shadow: 0px 0px 7px 0px #00000029;
    height: 2.8rem;
    border-radius: 4px;
    width: 100vh;
    font-weight: 400;
    outline: none;
    margin-right: 0.5rem;
    color: #5A5A5A;
    font-size: 0.95rem;
    border: 1px solid #00000033;
    padding-left: 0.5rem;
    max-width: 376px;
    max-height: 40px;
}
.containerAnexos{
    display: flex;
    flex-direction: row;
    background: white;
    margin-top: 1rem;
    border-radius: 5px;
    padding-bottom: 3.5rem;
    box-shadow: 0px 0px 6px #00000029;
}


.mainAnexos{
    padding: 1rem;
    width: 100%;
}
.divInput{
    display: flex;
    align-items: center;
    height: 33px;
    background: #009EBE;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border: unset;
    color: #fff;
}
.mainAnexos{
    padding: 2rem 1rem 1rem 1rem;
    width: 100%;
}

.divAnexos{
    display: flex;
    gap: 1%;
    justify-content: center;
}
.textArea {
    height: 90%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 0px #00000029;
    color: rgb(90, 90, 90);
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem;
    align-items: flex-start;
}



.containerIntelex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    margin-top: 1rem;
    border-radius: 5px;
    height: 35vh;
    box-shadow: 0px 0px 6px #00000029;
}
.mainIntelex{
    padding: 1rem;
    width: 50%;
}
.status{
    display: flex;
    align-items: center;
}
.status p{
    align-items: center;
    margin-top: 1rem;
    margin-left: 1rem;
    border-radius: 7px;
    color: #010066;
    padding: 0.5rem;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400;
    font-size: 0.95rem;
}

.DivAnexos{
    display: flex;
    justify-content: space-between;
    gap: 1%;
}
.anexos{
    width: 100%;
}

.divButton button:hover{
    box-shadow: 0px 0px 6px #4EBDEF;
}

.divAnexos{
    display: flex;
    gap: 1%;
    justify-content: center;
}

.divButton{
    display: flex;
    justify-content: space-between;
}

.divButton button{
    display: flex;
    align-items: center;
    height: 38px;
    background: #009EBE;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border: unset;
    color: #fff;
    width: 10%;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
}

.buttons{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1%;
}