
.infoUser{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
    padding: 0.5rem;
}

.infoUser img{
    width: 2rem !important;
    height: 2rem !important;
}
.infoUser img:hover{
    border-radius: 50%;
    box-shadow: 0px 0px 6px #4EBDEF;
}

.infoUser p{
    background: #2EAAC4;
    width: 34%;
    border-radius: 17px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.infos{
    display: flex;
    width: 20%;
    align-items: flex-start;
    cursor: pointer;
}
.infos button{
    background: none;
    border: none;
    cursor: pointer;
 }
 .infos img{
    width: 2rem !important;
    height: 2.5rem !important;
 }
.nameFunction{
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    color: #ffff;
    margin-left: 0.5rem;
}

.containerHeader{
    display: flex;
    justify-content: space-between;
    height: 63px;
    background-image: url('../../assets/icon_background.png');
    background-size: cover;
}

.logo img{
    width: 100px;
    margin-left: 1rem;
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo svg{
    margin-right: 1rem;
    cursor: pointer;
}

.logoutButton {
    background: #fff;
    color: #2EAAC4;
    border-radius: 6px;
    height: 36px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
}
.logout{
    width: 12%;
    height: 33px;
}

@media (max-width: 1024px){
    .infoUser{
        width: 48% !important;
        display: flex;
        align-items: center;
    }
}

@media (min-width: 1900px){
    .infoUser{
        width: 20%;
    }
    .logo img {
        margin: 5rem;
    }
}

@media (min-width: 1450px) and (max-width: 1900px) {
    .logo img {
        margin: 2.3rem;
    }
}


