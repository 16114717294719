.textPages{
    color: #010066;
    background: #DFDFDF;
    border-radius: 6px;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;  
    padding-left: 1rem;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1.1rem;
}


