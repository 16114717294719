


@media (min-height: 1020px){
    .divButton{
        zoom: 1.2 !important;        
    }
}

.divButton div{
    display: flex;
    align-items: center;
    height: 33px;
    background: #009EBE;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    margin-top: 0.5rem;
    border: unset;
    width: 100%;
    margin-bottom: 1rem;
}

.divButton div> img{
    width: 19%;
    margin-right: 0.3rem;
}

.divButton div:hover{
    box-shadow: 0px 0px 6px #4EBDEF;
}

.divButton label{
    color: #fff;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: start;
    margin-left: 0.2rem;
    font-family: 'Poppins', sans-serif !important;

}

