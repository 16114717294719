.rightMenu{
    box-shadow: 0px 0px 10px 0px #00000029;
    height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: #49B5CF;
}
.main{
    display: grid; 
    grid-template-columns: 1fr 4fr;
    background-image: url('../../assets/capa_branca.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 90vh;
}



@media (min-height: 900px){
    .rightMenu{
        height: 93vh;
    }
    .main{
        height: 93vh;
    }
    
}

@media (min-height: 1100px){
    .rightMenu{
        height: 94vh;
    }
    .main{
        height: 94vh;
    }
    
}

.itemMenu{
    padding: 0.65rem;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: 0.8rem;
    font-weight: 500;
    color: #010066;
    text-decoration: none;
    background-color: #fff;
    gap: 0.25rem;
    box-shadow: 0px 0px 6px #00000029;
    font-family: Poppins,sans-serif;
}

.titleMenu{
    align-items: center;
    color: #010066;
    display: flex;
    font-size: 0.9rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
    font-weight: 600;
}
.titleMenu p{
    margin-left: 0.5rem;
}

.selectedItemMenu{
    color: white;
    background: linear-gradient(90deg, #010066 0%, #6261C6 100%, #010066 100%) 0% 0% no-repeat padding-box !important;
    font-family: Poppins,sans-serif;
    box-shadow: 0px 0px 6px #00000029;
}
.inputMain{
    width: 100%;
    border: none;
    height: 2.2rem;
    outline: 0;
    margin-top: 0.5rem;
    padding-left: 5px;
    font-size: 1rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 3px;
    color: #5a5a5a;
    font-family: 'Montserrat', sans-serif;
}

.inputSigla{
    width: 95%;
    border: none;
    height: 2.2rem;
    outline: 0;
    margin-top: 0.5rem;
    padding-left: 5px;
    font-size: 1rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 3px;
    color: #5a5a5a;
    font-family: 'Montserrat', sans-serif;
}
.itemMenu::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    -webkit-transition: .15s ease-in-out;
    -o-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
}

.selectedItemMenu::before {
    width: .3rem;
    height: 2.1rem;
    /* top: 0.2rem; */
}

.admContainer{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 #00000029;
    height: 76vh;
    margin: 1rem 2rem;
    padding: 1.5rem 1.5rem 1rem;
    position: relative;
}

@media (min-height: 1000px){
    .admContainer{
        height: 84vh;
    }
}

.buttonPic{
    background: unset;
    border: unset;
    box-shadow: 0px 0px 7px 0px #00000029;
}