@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700;800&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
b,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: 'Montserrat', sans-serif;
    line-height: normal;
    text-decoration: none;
}

body {
    overflow-x: hidden;
    /* overflow-y: hidden; */

}

body,
html {
    height: 100% !important;
}

*::-webkit-scrollbar {
    width: 10px;
    height: 15px;
}


*::-webkit-scrollbar-track {
    background: white;
    border-radius: 15px;

}

*::-webkit-scrollbar-thumb {
    background-color: white !important;
    border-radius: 15px;
    border: 1px solid rgb(175, 175, 175) !important;
}
.swal2-title{
    font-size: 0.8rem !important;
}
.container-padrao {
    padding: 1rem;
    font-weight: 300;
    box-shadow: 0px 0px 7px 0px #00000029;
    max-height: auto;
    position: relative;
    border-radius: 14px;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.d-none {
    display: none !important;
}

.MuiAutocomplete-input {
    font-size: 1rem;
    width: 100%;
    border: none;
    height: 1.3rem !important;
    outline: 0;
    padding-left: 5px;
    font-size: 1rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 3px;
    color: #5A5A5A !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
}

.MuiOutlinedInput-notchedOutline {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    border: none !important;
    overflow: hidden;
}

.MuiInputAdornment-positionEnd {
    margin-left: -27px !important;
}

.MuiIconButton-root:hover {
    background: none !important;
}

.inputError {
    color: #EB4665;
    font-weight: 400;
    font-size: 85%;
    margin-top: 0.2rem;
}

.inputPadrao {
    box-shadow: 0px 0px 7px 0px #00000029;
    border: none;
    height: 2rem;
    border-radius: 4px;
    width: 100%;
    margin-top: 0.25rem;
    font-weight: 400;
    color: #5A5A5A;
    font-size: 0.95rem;
}

::placeholder {
    color: #8d8a8a;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.85rem;
}

.selectPadrao {
    box-shadow: 0px 0px 7px 0px #00000029;
    border: none;
    height: 2.2rem;
    border-radius: 4px;
    width: 100%;
    font-weight: 400;
    color: #5A5A5A;
    font-size: 0.95rem;
}

.rc-table tbody tr,
.MuiTable-root tbody tr,
.rc-table-footer {
    box-shadow: 0px 0px 6px 0px #ccc;
    border-radius: 5px;
    font-weight: 400;
    color: #5A5A5A;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    box-shadow: 0px 0px 6px 0px #4EBDEF;
    color: #4ebdef;
    background-color: white !important;
}

input:disabled{
    box-shadow: none;
    background: #dfdfdf;
}
.MuiTable-root tbody tr td {
    padding: 0.5rem;
    padding-left: 2rem;
}

.rc-table tbody tr td {
    padding: 0.5rem;
}

table {
    border-spacing: 0 0.55rem !important;
    border-collapse: separate !important;
    font-size: 90% !important;
    width: 100%;
    table-layout: inherit !important;
}

.MuiTable-root thead tr th span div {
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.90rem;
}


.MuiTable-root thead tr {
    background: #4EBDEF;
    color: white;

}

.rc-table thead tr {
    background: rgb(46, 170, 196);
    color: white;
    font-size: 0.8rem;
}

.rc-table thead tr th {
    padding: 0.5rem;
}

.MuiTable-root thead tr th {
    padding: 0.5rem;
    padding-left: 2rem;
}

.rc-table thead tr th:first-child,
.MuiTable-root thead tr th:first-child {
    border-radius: 5px 0 0 5px;
}

.rc-table thead tr th:last-child,
.MuiTable-root thead tr th:last-child {
    border-radius: 0 5px 5px 0;
}


.MuiTable-root tr td:nth-child(4) {
    width: 16% !important;
}

.MuiTable-root tbody tr td:last-child {
    padding: 0 !important;
}


.rc-table-header,
.MuiTableHead-root {
    margin-bottom: -1rem;
    position: sticky;
}

.rs-uploader-trigger input {
    display: none;
}

.MuiTableCell-body {
    color: #5A5A5A !important
}

.inativado tbody tr {
    filter: opacity(0.5);
}

.inativado {
    margin-top: -1rem;
}

.emptyArray {
    box-shadow: 0px 0px 6px 0px #ccc;
    margin-top: 1rem;
    padding: 0.5rem;
    font-size: 90%;
    color: #7C7C7C;
    border-radius: 5px;
    font-weight: 400;
}

.reportTable .rc-table thead tr,
.MuiTable-root thead tr {
    background: #1A374C;
    color: white;
    font-size: 90%;
}

.showFilter tbody tr:first-child {
    display: table-row !important;
}

.MuiPaper-elevation2 {
    box-shadow: none !important;
}

.MuiPaper-elevation2 {
    padding-bottom: 1rem !important;
    ;

}

.MuiTable-root {
    width: 98% !important;
    margin-left: 1rem;
    margin-right: 1rem;
}


div[class^='MuiFormControl-root MuiTextField-root MTableToolbar-searchField-'] {
    padding-left: 0px !important; 
    width: 100%;
}
div[class^='MTableToolbar-spacer-'] {
    flex:none !important;
}
div[class^='MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd'] {
    width: 20%;
    min-width: 200px;
}

.css-yem0l2-MuiPopper-root-MuiTooltip-popper{
    zoom: 0.8;
}
.css-179vq5o-MuiPopper-root-MuiTooltip-popper{
    zoom: 0.8;

}