
.infoUser{
    display: flex;
    align-items: center;
    width: 27%;
    justify-content: flex-end;
    margin-right: 0.5rem;
}

.infoUser img{
    width: 2rem !important;
    height: 2rem !important;
}
.infoUser img:hover{
    border-radius: 50%;
    box-shadow: 0px 0px 6px #4EBDEF;
}
.infoUser svg{
 cursor: pointer;
}
.infoUser p{
    background: #2EAAC4;
    width: 34%;
    border-radius: 17px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logoutButton {
    background: #fff;
    color: #2EAAC4;
    border-radius: 6px;
    height: 36px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
}
.logout{
    width: 12%;
    height: 44px;
    align-items: center;
}
.infos{
    display: flex;
    width: 34%;
    margin-right: 1rem;
    align-items: flex-start;
    cursor: pointer;
    margin-top: 0.5rem;
}
.infos button{
   background: none;
   border: none;
   cursor: pointer;
}

.nameFunction{
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    color:  #4D4D4D;;
    margin-left: 0.5rem;

}

.containerHeader{
    display: flex;
    justify-content: space-between;
    height: 63px;
}
.containerLogo{
    background-image: url('../../assets/icon_logo.svg');
    background-size: cover;
    zoom: 1.11 !important;
}
.containerLogo img{
    width: 5rem;
    height: 5rem;
}
.logo{
    display: flex;
    width: 36%;
    align-items: center;
    margin-top: 3rem;
}
.logo img{
    width: 400px;
    height: 18rem;
    display: flex;
    align-items: center;
    margin-left: -5rem;
}

.nameFunction{
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    color: #707070;
    margin-left: 0.5rem;
}

.tooltip{
    background-color: #2EAAC4;
}

@media (max-width: 1000px){
    .nameFunction{
       display: none;
    }
    .infoUser img{
        width: 2rem !important;
        height: 2rem !important;
        margin-right: 1.5rem;
    }
    .logo{
        display: none;
    }
    .containerHeader{
        justify-content: flex-end
    }
    .infoUser svg{
        display: none;
    }

}
@media (min-width: 1000px) and (max-width:1200px){
    .logo img{
        width: 82%;
    }
}

