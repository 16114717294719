.loading{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16rem 16rem;
    justify-items: center;
    text-align: center;
    background-color: #f6f9fc78;
    border-radius: 5px;
}

.spinnerLoading{
    margin-top: 20%;
    font-size: 2rem;
    color: #1A374C;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation: spin 1s linear infinite;
}

@-moz-keyframes spin { 
    0% { -moz-transform: rotate(0deg); }
    100% { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }