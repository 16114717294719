.textProduct{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 43%;
}
.textProduct label{
    color: #010066;
    font-weight: 600;
    display: flex;
    font-size: 1rem;
    align-items: center;
}

.textProduct svg{
    margin-left: 0.2rem;
}

.iconImage {
    transform: scale(1.3);
}   


