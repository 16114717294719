* {
  margin: 0;
}

.loginBackground {
  display: flex;
  overflow: hidden;
  height: 100vh;
  background-image: url('../../assets/capa_branca.png');
  background-size: cover;
  background-repeat: no-repeat;
  align-items: baseline;
  gap: 6rem;
}

.recaptcha {
  margin-left: 0.5rem;
}

.loginleft {
  width: 50vw;
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
}

.loginleft>h1 {
  color: #46B8D3;
  margin-bottom: 3rem;
  font-size: 3rem;
  text-align: center;
}

.loginleft strong {
  color: #46B8D3;
  font-weight: 800;
}

.loginright {
  background: white;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 70vh;
  align-items: center;
  margin-top: -4rem;
  padding-bottom: 0.5rem;
  border-radius: 13px;
  justify-content: center;
}

.loginright>h1 {
  margin: 10px;
  color: #46B8D3;
  font-size: 1.5rem
}

.inputsContainer {
  flex-direction: column;
}

.loginright div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginright input {
  width: 20vw;
  border: none;
  background-color: lightgray;
  margin: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  outline: none;
}

.buttonReset {
  border: none;
  background: none;
  outline: none;
  color: #46B8D3;
  margin-bottom: 1rem;
  cursor: pointer;
}

.titleFirst {
  font-size: 4rem;
  font-family: 'Poppins', sans-serif !important;
}
.divButton {
  border: none;
  height: 2.2rem;
  background-color: #009EBE;
  font-size: 15px;
  font-weight: bolder;
  color: white;
  border-radius: 30px;
  margin-top: 1rem;
  width: 76%;
    display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.divButtonRegister{
  border: none;
  height: 2.2rem;
  background-color: #ffffff;
  color: #009EBE;
  border: 1px solid #009EBE; 
   font-size: 15px;
  font-weight: bolder;
  border-radius: 30px;
  width: 76%;
    display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
}


.buttons>button:disabled {
  background-color: #95dced;
  transition: 0.5s ease-in-out;
  border: none;
  height: 2.2rem;
  font-size: 15px;
  font-weight: bolder;
  color: white;
  border-radius: 30px;
  margin-top: 1rem;
  width: 76%;
    display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.loginright div>svg {
  position: relative;
  right: 14%;
  cursor: pointer;

}

.loginright div {
  display: flex;
  align-items: center;

}

.divButton>button:hover {
  box-shadow: 0px 0px 5px 1px black;
  transition: 0.5s ease-in-out;
}

.divButton>button::after {
  background: black;
  transition: 2s ease-in-out;
}



.containerHome img {
  margin-left: -7rem;
  margin-top: -4rem;
  width: 25%;
}

.loginright>p {
  margin: 10px;
  font-weight: bold;
}

.loginright img {
  margin: 0px 10px;
}

@media (max-width: 480px) {

  .loginleft {
    display: none;
  }

  .loginright {
    width: 100vw;
    margin-top: 0px;
    padding-top: 30px;
    background-color: #46B8D3;
    clip-path: polygon(0 0, 100% 0, 90% 83%, 0% 90%);
  }

  .divButton>input {
    width: 50vw;
    background: white;
  }

  .divButton>button {
    background: white;
    color: #46B8D3;
  }
}

@media (max-width: 1000px) {
  .logo {
    display: none;
  }
}

@media (min-width: 1000px) and (max-width:1200px) {
  .logo img {
    width: 82%;
  }
}





/* ///////////////////// */
.title {
  color: #2EAAC4;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

.main {
  background-image: url('../../assets/capa_branca.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 100vh;
}

.titleFirst {
  font-size: 4rem;
}

@media (min-height: 900px){

  
  .titleFirst {
      font-size: 6.5rem;
  }
  .buttonsHome {
      display: flex;
      flex-direction: row;
      margin-top: 18rem !important;
  }

  .main {
    height: 100vh;
  }
  
}




.containerButtons {
  display: flex;
  justify-content: center;
  gap: 8rem;
}

.buttonsHome {
  display: flex;
  flex-direction: row;
  margin-top: 7rem;
}


@media (max-width: 1000px) {
  .buttonsHome {
      display: flex;
      gap: 3rem;
      margin-top: 4rem;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
  }



  .containerButtons {
      margin-left: 6%;
      margin-top: 0rem;
  }

  .titleFirst {
      font-size: 3rem;
  }

}

@media (min-width: 1000px) and (max-width:1300px) {

  .containerHome {
      background-size: 40% 89%;
  }

  .titleFirst {
      font-size: 3.5rem;
  }
  .buttonsHome {
      margin-top: 9rem !important;
  }
}

@media (min-width: 2000px) {
  .titleFirst {
      font-size: 6rem;
  }

  .containerButtons {
      margin-top: 10rem;
  }



}