

.divButtonHome span{
    position: absolute;
    margin-bottom: -2rem;
    color: white;
    text-align: center;
    border-radius: 4px;
    width: 21px;
    font-weight: 700;
    background-color: #dc1454;
}
@media (min-height: 1020px){
    .divButtonHome{
        zoom: 1.2 !important;       
        margin-left: 2rem; 
    }
}

.divButtonHome button{
    color: white;
    background: white;
    border: unset;
    padding: 0.5rem;
    border-radius: 30px;
    width: 12.5rem;
    height: 3rem;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: break-spaces;
    box-shadow: 0px 0px 6px #00000029;
}

.divButtonHome button> img{
    width: 19%;
    margin-right: 0.3rem;
}

.divButtonHome button:hover{
    box-shadow: 0px 0px 6px #4EBDEF;
}

.divButtonHome label{
    color: #2EAAC4;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: start;
    cursor: pointer ;
}
.divButtonHome{
    margin-right: 1rem;
}

@media (min-width: 1000px) and (max-width:1200px){
    .divButtonHome label{
        font-size: 0.7rem;
    }

    .divButtonHome button{
        width: 8.5rem;
    }
    
}


