.container{
    box-shadow: 0px 0px 6px #00000029;
}

.containerButtons{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    margin-top: -21px;

}
.containerButtons button{
    background: #010066;
    border: unset;
    padding: 0.5rem;
    border-radius: 7px;
    height: 2rem;
    cursor: pointer !important;;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 6px #00000029;
    color: #ffff;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: start;
    margin-left: 0.3rem;
    font-family: 'Poppins', sans-serif !important;
    cursor: pointer;
}

.containerButtons button> svg{
    margin-right: 0.3rem;
}

.main{
    padding: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    zoom: 0.9;
}
.background{
    background-image: url('../../assets/capa_branca.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
}


.statusCircle{
    height: 6vh;
    max-height: 35px;
    min-height: 32px;
}
.statusCircle img{
    width: 8%;
    height: 6vh;
    margin-bottom: -0.5rem;
    margin-right: 0.1rem;
    max-height: 35px;
    min-height: 32px;
    max-width: 9px;
}

.MuiIconButton-root:hover{
    background-color: aqua !important;
}

