.titlePages{
    color: #010066;
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 1rem;
    display: flex;
    align-items: center;
}

.titlePages h3{
    font-family: 'Poppins', sans-serif !important;
}
.titlePages img{
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
}
.back{
    cursor: pointer;
}
