.buttonAdd{
    color: #2EAAC4;
    border: unset;
    box-shadow: 0px 0px 7px 0px #00000029;
    background: #fff;
    display: flex;
    align-items: center;
    height: 2.3rem;
    width: 2.3rem;
    cursor: pointer;
    border-radius: 4px;
}