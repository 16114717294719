.container{
    display: flex;
    flex-direction: column;
    background: white;
    margin-top: 1rem;
    padding: 2rem 1rem 1rem 1rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px #00000029;
}
.main{
    padding: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
}
.background{
    background-image: url('../../assets/capa_branca.png');
    background-repeat: no-repeat;
    background-size: cover;
  zoom: 0.8;
}

@media (min-height: 900px){
    .background{
        zoom: 1;
    }
}

@media (min-height: 1100px){
    .background{
        zoom: 1;
        height: 95vh;
    }
}

.container input:disabled:hover {
    cursor: not-allowed
}

.container input:disabled {
    background-color: #ececec;
}

.mainProduct{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top:1rem;
    gap: 1%;
    justify-content: center;
}

.divProduct{
    display: flex;
    flex-direction: row;
    width: 48%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    height: 3.5rem;    
 }
 .nameProduct{
    display: flex;
    align-items: center;
    width: 36%;
    justify-content: flex-start;
    color: #8d8d8d;
    word-break: break-all;
    font-size: 0.95rem;
}

.inputProduct{
    box-shadow: 0px 0px 7px 0px #00000029;
    height: 2.5rem;
    border-radius: 4px;
    width: 100vh;
    font-weight: 400;
    outline: none;
    margin-right: 0.5rem;
    color: #8d8d8d;
    font-size: 0.95rem;
    border: 1px solid #00000033;
    padding-left: 0.5rem;
    max-width: 376px;

}
.selectProduct{
    box-shadow: 0px 0px 7px 0px #00000029;
    height: 2.8rem;
    border-radius: 4px;
    width: 100vh;
    font-weight: 400;
    outline: none;
    margin-right: 0.5rem;
    color: #5A5A5A;
    font-size: 0.95rem;
    border: 1px solid #00000033;
    padding-left: 0.5rem;
    max-width: 388px;
}
.title{
    color: #010066;
    height: 4rem;
    background-color: #DFDFDF;
    border-radius: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 1.5rem 0rem 1.5rem;
}
.title h2{
    font-family: 'Poppins', sans-serif !important;
    font-size: 1.2rem;
}

.containerAnexos{
    display: flex;
    flex-direction: row;
    background: white;
    margin-top: 1rem;
    border-radius: 5px;
    padding-bottom: 0.5rem;
    box-shadow: 0px 0px 6px #00000029;
}

.mainAnexos{
    padding: 1rem;
    width: 100%;
}

.mainIntelex{
    padding: 1rem;
    width: 50%;
}

.divButton button:hover{
    box-shadow: 0px 0px 6px #4EBDEF;
}



.divButton{
    display: flex;
    justify-content: flex-end;
}

.divButton button{
    display: flex;
    align-items: center;
    height: 3rem;
    background: #009EBE;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border: unset;
    color: #fff;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
}


.DivAnexos{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}
.anexos{
    width: 100%;
}