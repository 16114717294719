


@media (min-height: 1020px){
    .divButton{
        zoom: 1.2 !important;        
    }
}

.divButton button{
    background: #010066;
    border: unset;
    padding: 0.5rem;
    border-radius: 7px;
    height: 2rem;
    cursor: pointer !important;;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 6px #00000029;
}

.divButton button> img{
    width: 19%;
    margin-right: 0.3rem;
}



.divButton label{
    color: #ffff;
    font-size: 0.85rem;
    font-weight: 500;
    text-align: start;
    margin-left: 0.3rem;
    font-family: 'Poppins', sans-serif !important;
    cursor: pointer;
}
.divButton{
    margin-left: 1rem;
}

.divButton a{
    cursor: pointer !important;
}
