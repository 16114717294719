.title {
    color: #2EAAC4;
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.main {
    background-image: url('../../assets/capa_branca.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

}

.titleFirst {
    font-size: 4rem;
}

.containerHome {
    height: 100vh;
    background-image: url('../../assets/icon_folha.png');
    background-position: right bottom;
    background-size: 46% 94%;
    background-repeat: no-repeat;
    transform-style: preserve-3d;
}
@media (min-height:500px) and (max-height: 900px){
    .containerHome {
        height: 100vh;
        background-image: url('../../assets/icon_folha.png');
        background-position: right bottom;
        background-size: 46% 94%;
        background-repeat: no-repeat;
        transform-style: preserve-3d;
    }
}

@media (min-height: 900px){
    .containerHome {
        height: 100vh;
        background-image: url('../../assets/icon_folha.png');
        background-position: right bottom;
        background-repeat: no-repeat;
        transform-style: preserve-3d;
        background-size: 43% 96%;
    }
    
    .titleFirst {
        font-size: 6.5rem;
    }
    .buttonsHome {
        display: flex;
        flex-direction: row;
        margin-top: 18rem !important;
    }

    .main {
      height: 100vh;
    }
    
}


.containerHome:after {
    content: '';
    position: absolute;
    background: inherit;
    top: 8px;
    left: 50%;
    width: 95%;
    height: 100%;
    z-index: -1;
    transform: translateZ(-100px) translateX(-50%);
    filter: blur(9px);
    border-radius: 10px;
    box-shadow: 0px 0px 6px #00000029;
}

.containerButtons {
    margin-left: 6%;
    margin-top: 5rem;
}

.buttonsHome {
    display: flex;
    flex-direction: row;
    margin-top: 7rem;
}
.buttonsCollapsible{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.2rem;
    height: 21vh;
    overflow: auto;
    padding-bottom: 0.5rem;
}

.buttonsCollapsible button{
    color: #2EAAC4;
    background: white;
    border: unset;
    padding: 0.5rem;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: #00000029 0 2px;
}

@media (max-width: 1000px) {
    .buttonsHome {
        display: flex;
        gap: 3rem;
        margin-top: 4rem;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    .containerHome {
        /* display: grid;
        grid-template-columns: 1.5fr 1fr; */
        background-image: none;
        background-size: cover;
        height: 100vh;
        overflow: hidden;
    }

    .containerButtons {
        margin-left: 6%;
        margin-top: 0rem;
    }

    .titleFirst {
        font-size: 3rem;
    }

}

@media (min-width: 1000px) and (max-width:1300px) {

    .containerHome {
        background-size: 40% 89%;
    }

    .titleFirst {
        font-size: 3.5rem;
    }
    .buttonsHome {
        margin-top: 9rem !important;
    }
}

@media (min-width: 2000px) {
    .titleFirst {
        font-size: 6rem;
    }

    .containerButtons {
        margin-top: 10rem;
    }



}