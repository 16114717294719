.table {
    height: 48vh;
    padding: 0.5rem;
    width: 97%;
    display: flex
}

.button {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
}

.button {
    background: rgb(46, 170, 196);
    color: white;
    font-size: 1rem;
    border: none;
    height: 2.2rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #ccc;
    cursor: pointer;
    align-items: center;
}

.select{
    box-shadow: 0px 0px 7px 0px #00000029;
    border: none;
    height: 2.2rem;
    border-radius: 4px;
    width: 85%;
    margin-right: 0.5rem;
    font-weight: 400;
    color: #5A5A5A;
    font-size: 0.95rem;
}
.title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-image: url('../../assets/icon_modal.png');
    background-size: cover;
    align-items: center;
    height: 50px;
}

.title img{
    width: 2rem;
    margin-right: 1.5rem;
}
.title h1{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 500;
}


.table table tbody tr:hover{
    box-shadow: 0px 0px 6px 0px #4EBDEF;
    color: #4ebdef;
    background-color: white !important;
    cursor: pointer;
}