
.imgDiv {
    display: flex;
    flex-direction: row;
    column-gap: 0.8rem;
    flex-wrap: wrap;
    margin-left: 0.5rem;
}

.imgDiv .img {
    width: 120px;
    height: 100px;
    box-shadow: 0px 0px 6px #00000049;
    object-fit: cover;
}
.inputContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.inputContainer input{
    box-shadow: 0px 0px 7px 0px #00000029;
    height: 1.5rem;
    border-radius: 4px;
    font-weight: 400;
    outline: none;
    color: #5A5A5A;
    font-size: 0.95rem;
    border: 1px solid #00000033;
    padding-left: 0.5rem;
}

.imgContainer { 
   display: flex;
    flex-direction: column;
    position: relative; 
    width: 8.5rem;
    height: 8rem;
}
.anexosDiv{ 
    width: 120px;
    height: 100px;
    box-shadow: 0px 0px 6px #00000049;
    object-fit: cover;
    align-items: center;
    display: flex;
    justify-content: center;
}
.anexosDiv button{ 
    border: none;
    background: transparent;
    cursor: pointer;
}