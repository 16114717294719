.text{
  display: flex;
  flex-direction: row;

}

.text div{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem;
  margin-top: 1rem;
  font-size: 0.90rem;
  color: #5A5A5A;
  box-shadow: 0px 0px 7px 0px #00000029;
  border-radius: 4px;
  height: 2rem;
}