.mainAvaliação{
    display: flex;
    padding-left: 0.85rem;
    width: 97%;
    zoom: 0.9;
}
.mainAvaliação hr{
    border: 0;
    height: 53vh;
    background-color: #cecece;
    border: 0;
    width: 1px;
}
.title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-image: url('../../assets/icon_modal.png');
    background-size: cover;
    height: 3rem;
}
.title img{
    width: 2rem;
    margin-right: 1.5rem;
}
.title h1{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 500;
}
.answers h1{
    color: #009EBE;
    margin-bottom: 0.85rem;
    font-size: 1.2rem;
    line-height: 19px;
}
.questions{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 97%;
    margin-right: 1rem;
}
.divQuestions{
    width: 100%;
    margin-bottom: 0.5rem;
}
.divQuestions h2{
    color: #010066;
    font-weight: 600;
    display: flex;
    font-size: 0.85rem;
    align-items: center;
    line-height: 15px;
}

.answers {
    margin-top: 0.5rem;
    width: 40%;
    margin-left: 1rem;
}

.containerAnswers{
    display: flex;
    flex-direction: column;
}

.divAnswers{
    display: flex;
    margin-bottom: 0.85rem;
}
.divAnswers label{
    color: #010066;
    font-weight: 600;
    display: flex;
    font-size: 1rem;
    align-items: center;
    font-size: 0.85rem;

}

.divAnswers textarea{
    width: 100%;
    resize: none;
    outline: none;
    box-shadow: 0px 0px 7px 0px #00000029;
    border: none;
    border-radius: 4px;
    margin-top: 0.25rem;
    font-weight: 400;
    color: #5A5A5A;
    font-size: 0.95rem;
    height: 100%;
}

.divQuestions input[type=text],.divQuestions input[type=date]{
    box-shadow: 0px 0px 7px 0px #00000029;
    border: none;
    height: 2rem;
    border-radius: 4px;
    width: 100%;
    margin-top: 0.25rem;
    font-weight: 400;
    color: #5A5A5A;
    font-size: 0.95rem;
    padding-left: 0.5rem;
}
.divQuestions input[type=date]{
    text-align: center;
     height: 2.2rem;
    color: #009EBE;
    margin-top: 0.22rem;
}

.divButton{
    display: flex;
    justify-content: flex-end;
    padding-right:1rem;
}
.divButton button{
    display: flex;
    align-items: center;
    height: 38px;
    background: #009EBE;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border: unset;
    color: #fff;
    width: 14%;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
}
.divQuestions label{
    margin-right: 0.5rem;
    font-size: 0.85rem;
    color: #010066;
    margin-left: 0.3rem;
}