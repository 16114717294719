.uploadDiv {
    font-size: 0.9rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.uploadDiv span {
    color: #B2B0B0;
}

.uploadInput {
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    padding: 0.2rem 0.25rem;
    gap: 1%;
    margin-bottom: 1rem;
}
.divInput{
    display: flex;
    align-items: center;
    height: 33px;
    background: #009EBE;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border: unset;
    color: #fff;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.inputContainer input{
    box-shadow: 0px 0px 7px 0px #00000029;
    height: 1.5rem;
    border-radius: 4px;
    font-weight: 400;
    outline: none;
    color: #5A5A5A;
    font-size: 0.95rem;
    border: 1px solid #00000033;
    padding-left: 0.5rem;
}

.buttonInput{
    color: #009EBE;
    width: 1rem;
    font-size: 1rem; 
    padding: 0.35rem 0.2rem 0rem 0.2rem;
    margin-right: 0.5rem ;
    cursor: pointer;
    width: 100%;
    background: none;
    border: none;
    height: 2rem;
    display: flex;
    justify-content: flex-start;
}

.uploadInput svg{
    cursor: pointer;
}
.imgDiv {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    flex-wrap: wrap;
}

.imgDiv .img {
    width: 120px;
    height: 100px;
    box-shadow: 0px 0px 6px #00000049;
    object-fit: cover;
}

.imgContainer { 
    display: flex;
    flex-direction: column;
    position: relative; 
    width: 7.5rem;
}

.anexosDiv{ 
    box-shadow: 0px 0px 6px #00000049;
    object-fit: cover;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 120px;
    height: 100px;
}

.imgContainer .deleteImgIcon { 
    position: absolute; 
    top: -10px;
    right: -2px;
    transition: 400ms;
    opacity: 0;
    width: 1.2rem;
    z-index: 9999;
    cursor: pointer;
    font-size: 1rem;
    color: #FF4141;
}

.imgContainer:hover .deleteImgIcon {
    opacity: 1;
    font-size: 1.5rem;
}
.anexosDiv button{ 
    border: none;
    background: transparent;
    cursor: pointer;
}