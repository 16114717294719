.container{
    box-shadow: 0px 0px 6px #00000029;
    margin-top: 00.5rem;
}

.containerButtons{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    margin-top: -21px;

}

.main{
    padding: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
}
.background{
    background-image: url('../../assets/capa_branca.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
}

.statusCircle{
    height: 6vh;
    max-height: 35px;
    min-height: 32px;
}
.statusCircle img{
    width: 8%;
    height: 6vh;
    margin-bottom: -0.5rem;
    margin-right: 0.1rem;
    max-height: 35px;
    min-height: 32px;
    max-width: 9px;
}
