.textProduct{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 7px 0px #00000029;
    border-radius: 4px;
    height: 3.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 32.5%;
    margin-bottom: 0.5rem;
}
.textProduct label{
    color: #010066;
    font-weight: 600;
    display: flex;
    font-size: 1rem;
}
.textProduct label svg{
    margin-left: 8px;
}
.textProduct span img{
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 8px;
}

.textProduct span{
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: flex-start;
    color: #8d8d8d;
    word-break: break-all;
    font-size: 0.95rem;
}



